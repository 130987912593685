import React from 'react';

type P = {
  size?: number;
};

const KakaoIcon: React.FC<P> = ({ size = 22 }) => (
  <svg width={size} height={size * 0.91944444} viewBox="0 0 36 33.1">
    <path
      fill="#381E1F"
      d="M18,0C8.1,0,0,6.4,0,14.2c0,5.1,3.4,9.6,8.5,12.1c-0.4,1.4-1.4,5.1-1.6,5.9c-0.2,1,0.4,1,0.8,0.7c0.3-0.2,4.9-3.3,6.9-4.7c1.1,0.2,2.2,0.2,3.3,0.2c9.9,0,18-6.4,18-14.2S27.9,0,18,0z"
    />
    <path
      fill="#EED821"
      d="M11.2,11.1c-1.3,0-5.7,0-5.7,0c-1.1,0-1.1,1.8,0,1.8h1.9c0,0,0,5.1,0,6.3c0,0.9,2,0.9,1.9,0l0-6.3c0,0,0.6,0,1.9,0C12.4,13,12.4,11.1,11.2,11.1z"
    />
    <path
      fill="#EED821"
      d="M15.6,11.7c-0.3-0.6-1.9-0.9-2.3,0c-0.5,1-2.1,5.8-2.7,7.1c-0.4,0.9,1.3,1.7,1.7,0.8l0.4-1.3l3.4,0c0,0-0.1,0.1,0.3,1.3c0.4,1,2.2,0.3,1.8-0.7C17.8,17.4,16,12.4,15.6,11.7z M13.4,16.5l1.1-3.3l1,3.3H13.4z"
    />
    <path
      fill="#EED821"
      d="M23.5,18c-0.9,0-2.5,0-2.5,0s0-4.9,0-6c0-1.1-2-1.1-2,0c0,1.6,0,6.9,0,6.9s-0.2,1,0.7,1c0.9,0,2.9,0,3.8,0S24.4,18,23.5,18z"
    />
    <path
      fill="#EED821"
      d="M31.1,18.6l-2.6-3.5c0,0,1.8-1.9,2.5-2.6c0.4-0.4-0.9-1.7-1.3-1.3c-0.4,0.4-3.2,3.2-3.2,3.2s0-1.8,0-2.7c0-0.8-1.9-0.7-1.9,0.1c0,0.7,0,7.2,0,7.7c0,0.8,1.9,0.8,1.9,0c0-0.9,0-2.5,0-2.5l0.6-0.5l2.5,3.4C30.1,20.4,31.6,19.2,31.1,18.6z"
    />
  </svg>
);

export default KakaoIcon;
